@import './variables.less';

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .extra-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.action-green {
  margin-right: 20px;
  color: #258835;
}
.action-red {
  margin-right: 20px;
  color: #d33434;
}
.action-black {
  margin-right: 20px;
  color: black;
}
.light-btn {
  background-color: white;
  color: black;
  border-radius: 6px;
}
.border-round {
  border-radius: 6px;
}
.dark-btn {
  background-color: black;
  color: white;
  border-radius: 6px;
}
.dark-btn-hover {
  &:hover {
    border: 1px solid black;
  }
}
.status-container {
  width: 150px;
  background: #ddd;
  border-radius: 20px;
  padding: 6px;
  font-size: 10px;
}
.message-wrapper {
  margin-top: 2rem;
  width: 100%;
  height: 3rem;
  background-color: #f9f9fa;
}
.search-contaier {
  .ant-input-affix-wrapper > input.ant-input {
    height: 35px !important;
  }
  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    height: 49px !important;
  }
}
.text-head {
  font-family: @font-family;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  line-height: 20px;
  color: #09263d;
}
.text-body {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0.25rem;
  font-family: @font-family;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  line-height: 20px;
  color: #686c80;
}
.text-sub-head {
  font-family: @font-family;
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  line-height: 20px;
  color: #09263d;
}

.format {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.primary-background-color {
  background-color: #f5fbff;
}

.font-green {
  color: @success-color-pattern;
}

.b-dark-blue {
  background-color: @darkBlueColor;
}

.btn-primary-redesigned {
  &:extend(.b-dark-blue);
  border: 0;
  border-radius: 8px;
  color: white;

  &:hover,
  &:focus {
    background-color: lighten(@darkBlueColor, 10%);
    color: white;
  }

  &:active {
    background-color: lighten(@darkBlueColor, 15%);
  }
  &:disabled,
  &:disabled:hover {
    color: @grey;
  }
}

.btn-secondary-redesigned {
  background-color: @btnSecondaryColor;
  color: @darkBlueColor;
  border: 1px solid #f0f0f0;
  border-radius: 5px;

  &:hover,
  &:focus {
    color: @darkBlueColor;
    background-color: darken(@btnSecondaryColor, 5%);
  }

  &:active {
    color: @darkBlueColor;
    background-color: darken(@btnSecondaryColor, 10%);
  }
}

.btn-default-redesigned {
  background-color: transparent;
  color: @darkBlueColor;
  border: 1px solid @darkBlueColor;

  &:hover,
  &:focus {
    color: @darkBlueColor;
    border: 1px solid @darkBlueColor;
    background-color: rgba(0, 0, 0, 0.05);
  }

  &:active {
    color: @darkBlueColor;
    border: 1px solid @darkBlueColor;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.custom-radio-btn-group {
  .ant-radio-group {
    .ant-radio-button-wrapper {
      color: #09263d;
      border-color: #f0f0f0;
      background-color: #f5fbff;
      border-radius: 25px;
      border: none;
      margin: 5px 15px 5px 0px;
      font-size: 13px;
      letter-spacing: 0.2px;
      height: 36px;
    }

    .ant-radio-button-checked {
      background-color: #09263d;
      color: #feffff;
      border-radius: 25px;
      border: none;
    }

    *::before,
    *::after {
      background: none;
      background-color: transparent !important;
    }

    .ant-radio-button-wrapper {
      border-radius: 25px;
      border: 1px solid;
      color: #09263d;
      border-color: #f0f0f0;
      background-color: #f5fbff;
    }

    .ant-radio-button-wrapper-checked {
      background-color: #09263d;
      color: #feffff;
      border-radius: 25px;
      border: none;
    }

    input {
      width: 100px;
      background-color: #f1f1f1;
      color: #09263d;
      border-bottom-color: #9d9d9d;
      outline: 0;
      text-align: center;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #09263d;
      opacity: 1;
      /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #09263d;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #09263d;
    }
  }

  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #1c1b1d;
    border-color: #1c1b1d !important;
    color: #feffff;
  }
}

.ant-table-thead > tr > th {
  font-weight: 600;
}

.custom-shadow {
  box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.1);
}

.custom-shadow-2 {
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}

// Antd preview image wrap/background/overlay
.ant-image-preview-wrap {
  background-color: #000000bd;
}

.ant-image-preview-switch-left,
.ant-image-preview-switch-right {
  background-color: #ddddddbf;
  &:hover {
    background-color: #dddddd78;
  }
  svg {
    color: #000;
  }
}