:root {
  --border-radius: 4px;
  --gray-100: #f5f5f5;
  --gray-400: #ddd;
  --primary-color: #0060ff;
}

body {
  margin: 0;
  font-family: Gilroy, Larsseit, 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

h1 {
  font-size: 2em
}

h2 {
  font-size: 1.5rem;
}
@font-family: Larsseit;@primary-color: #4040F2;@item-active-bg: #e6f4ff;@select-item-selected-bg: #e6f4ff;@table-selected-row-bg: #e6f4ff;@success-color: #00C878;@success-color-pattern: #00EA8C;@primary-background-color: #F5FBFF;