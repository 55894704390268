.mobile-number-input-container {
  font-family: inherit;
  padding: 1px 0;
  display: flex;
  align-items: center;

  .form-control {
    background: unset;
  }

  .mobile-number-input {
    width: 100%;
    border: none;
    padding-left: 80px;
    font-size: 16px;
    &:focus {
      box-shadow: none;
    }
  }

  .mobile-number-button {
    border: none;
    background-color: #f5f5f5 !important;
    min-width: 70px;
    .selected-flag {
      border-radius: 0;
      padding-left: 22px;
      width: 100%;
      &:hover {
        background-color: #f5f5f5 !important;
      }
    }
    .flag {
      transform: scale(1.3);
      margin-right: 10px;
    }
  }

  .mobile-number-dropdown {
    .search {
      background-color: white;
      z-index: 1;
      padding: 10px;
      .search-emoji {
        display: none;
      }
      .search-box {
        width: 100%;
        line-height: 20px;
        margin-left: 0;
      }
    }
  }
  .flag-dropdown.open {
    z-index: 50;
  }
}

@font-family: Larsseit;@primary-color: #4040F2;@item-active-bg: #e6f4ff;@select-item-selected-bg: #e6f4ff;@table-selected-row-bg: #e6f4ff;@success-color: #00C878;@success-color-pattern: #00EA8C;@primary-background-color: #F5FBFF;