.layout {
  .sidebar {
    overflow-y: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    background-color: #fff;
    .sidebar-toggle {
      position: absolute;
      bottom: 24px;
      right: 0;
    }
  }

  .site-layout {
    margin-left: 200px;

    .site-layout-background {
      background: #fff;
    }

    .app-header {
      width: calc(100% - 200px);
      // background-color: transparent;

      background-color: #f0f2f5;
    }

    .content-section {
      overflow: initial;
      min-height: calc(100vh - 80px);
      padding: 20px 20px 0px 20px;
      border-radius: 0.25rem;
      // background-color: #f0f2f5 !important;
    }

    &.side-nav-collapsed {
      margin-left: 80px;

      .app-header {
        width: calc(100vw - 80px);
      }
    }
  }

  .custom-navbar {
    .ant-menu-item,
    .ant-menu-submenu-title {
      padding-left: 20px !important;
      padding-right: 12px !important;
    }

    .ant-menu-sub.ant-menu-inline {
      padding-left: 48px !important;
    }
  }
}

.download-stls {
  .blinking {
    animation: blinking 1.5s infinite;
  }

  @keyframes blinking {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }

  .ant-btn-circle {
    right: 40px !important;
  }

  .custom-accordion,
  .ant-collapse,
  .ant-collapse-item,
  .ant-collapse-header {
    border-top-left-radius: 25px !important;
    border-top-right-radius: 25px !important;
  }

  .custom-accordion {
    padding-top: 0;
    .ant-collapse-header {
      padding-top: 15px !important;
      padding-bottom: 15px !important;
      background-color: #f0f0f2 !important;
    }
    .ant-collapse-content {
      border-top: 1px solid #d9d9d933;
    }
  }
  .download-progress-box {
    padding: 10px 15px;
    &:hover {
      background-color: #fafafa;
    }
  }
  .ant-collapse-content-box {
    padding: 0px !important;
  }
}

@font-family: Larsseit;@primary-color: #4040F2;@item-active-bg: #e6f4ff;@select-item-selected-bg: #e6f4ff;@table-selected-row-bg: #e6f4ff;@success-color: #00C878;@success-color-pattern: #00EA8C;@primary-background-color: #F5FBFF;