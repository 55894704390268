.ant-card {
  .ant-card-cover {
    margin: 0;
  }
}

.ant-checkbox {
  .ant-checkbox-inner {
    border-radius: 0.25rem;
    border: 2px solid #d9d9d9;
  }
}

.ant-picker-panel-container,
.ant-picker,
.ant-input,
.ant-modal-content {
  border-radius: 8px;
}

.ant-input-number-input {
  border-radius: 8px;
}

.ant-upload-list-item,
.ant-select-selector {
  border-radius: 8px !important;
}

.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.ant-input-affix-wrapper,
.ant-input-number,
.ant-picker-range {
  border-radius: 8px;
}

.ant-table-tbody > tr > td {
  padding: 12px 16px;
}
.ant-table-measure-row > tr > td {
  padding: 0;
}

.ant-pagination-item,
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-pagination-item-active {
  border-radius: 8px;
  background-color: #d9d9d9;
  color: black;
  a {
    color: black;
  }
}

.ant-popover-inner,
.ant-select-dropdown,
.ant-dropdown-menu {
  border-radius: 8px !important;
}

.ant-btn {
  border-radius: 8px;
}

.ant-tag {
  border-radius: 4px;
}

.ant-form-item-label {
  font-weight: 600;
}

.ant-modal-header {
  border-radius: 8px 8px 0 0;
}

.ant-radio-group {
  label:first-of-type {
    border-radius: 8px 0 0 8px;
  }
  label:last-of-type {
    border-radius: 0 8px 8px 0;
  }
}

.ant-modal-mask {
  background-color: rgb(0 0 0 / 14%) !important;
}
