.custom-accordion {
  background-color: white;
  padding: 0.5rem 0;
  border-radius: 8px;

  .ant-collapse {
    border: none;
  }

  .ant-collapse-header {
    border: none !important;
    background-color: white !important;
    // border-left: 3px solid #fae347 !important;
    font-family: @font-family;
    font-size: 16px;
    font-weight: 600;
    // color: @heading-color;
    padding: 6px 20px !important;
    // .anticon {
    //     top: 0px !important;
    //     font-size: 14px !important;
    // }
  }

  .ant-collapse-extra {
    margin-top: 2px;
  }

  .add-more-btn {
    margin-top: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    cursor: pointer;
    border: none;

    span:first-child {
      line-height: initial;
      margin-top: -2px;
    }
  }

  .ant-collapse-content {
    border: none;
  }

  .ant-collapse > .ant-collapse-item {
    border: none;
  }

  .p-panel-content {
    border: none !important;
  }

  .collapse-btn {
    .anticon.anticon-down {
      margin-left: 0px;
      line-height: 0;
    }
  }
}
.image-backdrop {
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.4;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
}

.image-preview-btn {
  position: absolute;
  // top: 40%;
  // left: 35%;
  z-index: 9;
}

.small-image-container {
  min-height: 140px;
  position: relative;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    display: block;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .overlay {
    opacity: 0;
  }
  &:hover {
    .overlay {
      opacity: 1;
    }
  }
}

@font-family: Larsseit;@primary-color: #4040F2;@item-active-bg: #e6f4ff;@select-item-selected-bg: #e6f4ff;@table-selected-row-bg: #e6f4ff;@success-color: #00C878;@success-color-pattern: #00EA8C;@primary-background-color: #F5FBFF;