.custom-signin-input {
  height: 60px;
  box-shadow: 0px 10px 10px rgb(187 187 187 / 25%);

  > input.ant-input {
    font-size: 14px;
    font-weight: 600;
    color: #050d43 !important;
    background-color: white !important;
    height: 24px;
    margin-top: 20px;
    margin-left: 8px;
    letter-spacing: 0.5px;
  }
}

@font-family: Larsseit;@primary-color: #4040F2;@item-active-bg: #e6f4ff;@select-item-selected-bg: #e6f4ff;@table-selected-row-bg: #e6f4ff;@success-color: #00C878;@success-color-pattern: #00EA8C;@primary-background-color: #F5FBFF;