@import '~antd/dist/antd.less';
@import './styles/variables.less';
@import './styles/fonts.css';
@import './styles/reset.css';
@import './styles/ant-override.less';

@tailwind base;
@tailwind components;
@tailwind utilities;

.emoji-popover .ant-popover-inner-content {
  padding: 0;
}

.radio-green-dot input[type='radio']:checked {
  border-color: #039f61 !important;
  background: #039f61;
}

@import './styles/custom.less';

@font-family: Larsseit;@primary-color: #4040F2;@item-active-bg: #e6f4ff;@select-item-selected-bg: #e6f4ff;@table-selected-row-bg: #e6f4ff;@success-color: #00C878;@success-color-pattern: #00EA8C;@primary-background-color: #F5FBFF;