/* FONTS */

/* LARSSEIT */
@font-face {
  font-family: 'Larsseit';
  src: url('../assets/fonts/Larsseit.otf');
  font-style: normal;
  font-weight: 100;
}

@font-face {
  font-family: 'Larsseit-Medium';
  src: url('../assets/fonts/Larsseit-Medium.otf');
  font-style: normal;
  font-weight: 500;
}
/* END OF LARSSEIT */

/* GILROY */
@font-face {
  font-family: Gilroy;
  src: local('Gilroy Light'), local('Gilroy-Light'), url('../assets/fonts/gilroy/Gilroy-Light.otf');
  font-weight: 300;
}

@font-face {
  font-family: Gilroy;
  src: local('Gilroy Regular'), local('Gilroy-Regular'), url('../assets/fonts/gilroy/Gilroy-Regular.otf');
  font-weight: 400;
}

@font-face {
  font-family: Gilroy;
  src: local('Gilroy Medium'), local('Gilroy-Medium'), url('../assets/fonts/gilroy/Gilroy-Medium.otf');
  font-weight: 500;
}

@font-face {
  font-family: Gilroy;
  src: local('Gilroy SemiBold'), local('Gilroy-SemiBold'), url('../assets/fonts/gilroy/Gilroy-SemiBold.otf');
  font-weight: 600;
}

@font-face {
  font-family: Gilroy;
  src: local('Bold'), local('Gilroy-Bold'), url('../assets/fonts/gilroy/Gilroy-Bold.otf');
  font-weight: 700;
}
/* END OF GILROY */

@font-family: Larsseit;@primary-color: #4040F2;@item-active-bg: #e6f4ff;@select-item-selected-bg: #e6f4ff;@table-selected-row-bg: #e6f4ff;@success-color: #00C878;@success-color-pattern: #00EA8C;@primary-background-color: #F5FBFF;