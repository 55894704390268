.upload-file-name {
  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.align-delete-btn {
  .anticon-delete {
    margin-top: -6px;
  }
}

@font-family: Larsseit;@primary-color: #4040F2;@item-active-bg: #e6f4ff;@select-item-selected-bg: #e6f4ff;@table-selected-row-bg: #e6f4ff;@success-color: #00C878;@success-color-pattern: #00EA8C;@primary-background-color: #F5FBFF;