.practice-input-container {
  height: 40px;
  .ant-select-selector {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px !important;
  }
  .ant-select-selection-search {
    margin-top: 6px !important;
  }
}
.org-input-edit-field {
  .ant-input-group > .ant-input:first-child,
  .ant-input-group-addon:first-child {
    height: 40px;
  }
}
.practice-select-container {
  .ant-select-selector {
    height: 40px !important;
    padding-top: 0.1rem !important;
  }
}

.practice-select-container2 {
  .ant-select-selector {
    max-height: 70px !important;
    min-height: 40px;
    height: 100%;
    padding-top: 0.3rem !important;
  }
}

.practices-table-container {
  .ant-table-thead > tr > th {
    font-size: 14px;
    line-height: 20px;
    background-color: transparent;
    font-weight: 500;
  }
  .ant-table-tbody > tr > td {
    font-size: 12px;
    font-weight: 500;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }
}

.mobile-select-container {
  .ant-select-selector {
    height: 50px !important;
    padding-top: 0.4rem !important;
  }
}
.mobile-input-container {
  padding: 0px !important;
  height: 50px;
  .ant-select-selector {
    display: flex;
    justify-content: center;
    height: 50px !important;
  }
  .ant-input-number-input {
    display: flex;
    justify-content: center;
    width: 170px !important;
    height: 50px !important;
  }
}

.select-container {
  .ant-select-selector {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

@font-family: Larsseit;@primary-color: #4040F2;@item-active-bg: #e6f4ff;@select-item-selected-bg: #e6f4ff;@table-selected-row-bg: #e6f4ff;@success-color: #00C878;@success-color-pattern: #00EA8C;@primary-background-color: #F5FBFF;